<template>
    <div>
        <a
            class="popNotice flexH width margin between"
            @touchmove="close"
            ref="notificaiton-bar"
            @click.prevent="$router.push('/record')"
        >
            <div class="info flexV padding margin height">
                <span class="fL title">{{content.title}}</span>
                <span class="fL double"
                    >{{content.message}}</span
                >
                <span class="fS gray">{{content.time}}</span>
            </div>
            <div class="reward flexV padding margin center middle">
                <div class="img"><img src="@/assets/icon/point.svg" /></div>
                <span class="fM">{{content.points}}</span>
            </div>
        </a>
    </div>
</template>
<script>

export default {
    name: "NotificationBar",
    props: {
        open: {
            type: Boolean,
            default: false
        },
        content: {
            type: Object,
            default: () => {
                return {
                    title: "title",
                    message: "message",
                    time: "2022-05-23",
                    points: "20"
                }
            }
        },
        speed: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
           
        };
    },
    mounted() {
        // this.show();
    },
    methods: {
        show() {
            let target = this.$refs['notificaiton-bar'];
            target.style.display = "flex";
            target.classList.add("moveIn");
            setTimeout(function () {
                target.classList.remove("moveIn");
            }, 500);
        },
        close() {
            let target = this.$refs['notificaiton-bar'];
            target.classList.add("moveOut");
            setTimeout(function () {
                target.style.display = "none";
                target.classList.remove("moveOut");
            }, 500);
        }
    },
    watch: {
        open(val) {
            if(val) {
                this.show();
                setTimeout(() => {
                    this.$emit('close');
                }, this.speed * 1000);
            }else {
                this.close();
            }
        }
    }
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
